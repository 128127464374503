// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZPtEVivUZ1Vy_dUa9MHI{pointer-events:none;position:fixed;z-index:99999;width:400px;height:80px;bottom:20px;left:20px;transform:translateY(100%);opacity:0;transition:all .2s ease}.ZPtEVivUZ1Vy_dUa9MHI.PjBYsAr3bNdN9JQ54ahc{transform:translateY(0%);opacity:1}.ZPtEVivUZ1Vy_dUa9MHI>div{height:100%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/Toast/components/ToastController/styles.scss"],"names":[],"mappings":"AACA,sBACE,mBAAA,CAEA,cAAA,CACA,aAAA,CACA,WAAA,CACA,WAAA,CACA,WAAA,CACA,SAAA,CACA,0BAAA,CACA,SAAA,CACA,uBAAA,CAEA,2CACE,wBAAA,CACA,SAAA,CAGF,0BACE,WAAA","sourcesContent":["$theme: connectivf;\n.ToastController {\n  pointer-events: none;\n\n  position: fixed;\n  z-index: 99999;\n  width: 400px;\n  height: 80px;\n  bottom: 20px;\n  left: 20px;\n  transform: translateY(100%); // position offscreen\n  opacity: 0;\n  transition: all 0.2s ease;\n\n  &.-show {\n    transform: translateY(0%);\n    opacity: 1;\n  }\n\n  > div {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToastController": "ZPtEVivUZ1Vy_dUa9MHI",
	"-show": "PjBYsAr3bNdN9JQ54ahc"
};
export default ___CSS_LOADER_EXPORT___;
