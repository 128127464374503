// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rlagCqz_wS57xuDWUp_D{text-align:center;padding:24px 0}", "",{"version":3,"sources":["webpack://./../next-react/src/components/forms/MultiForm/styles.scss"],"names":[],"mappings":"AAIE,sBACE,iBAAA,CACA,cAAA","sourcesContent":["$theme: connectivf;\n@import \"../../../scss/variables.scss\";\n\n.MultiForm {\n  &_progress {\n    text-align: center;\n    padding: $spacing-md 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MultiForm_progress": "rlagCqz_wS57xuDWUp_D"
};
export default ___CSS_LOADER_EXPORT___;
