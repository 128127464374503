// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gAqzShSSezJAfYxj6E4X{height:100%;width:100%;display:flex;align-items:center;justify-content:center}.gAqzShSSezJAfYxj6E4X.eKCkNcQ0N2h8zeth1lpA{border-radius:50%}.f9dMY52B6cMEZ8nqGenJ{font-size:100px !important}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/Camera/components/CameraFeedPlaceholder/styles.scss"],"names":[],"mappings":"AAGA,sBACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,2CACE,iBAAA,CAGF,sBACE,0BAAA","sourcesContent":["$theme: connectivf;\n@import \"../../../../../scss/variables.scss\";\n\n.CameraFeedPlaceholder {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &.-round {\n    border-radius: 50%;\n  }\n\n  &_icon {\n    font-size: 100px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CameraFeedPlaceholder": "gAqzShSSezJAfYxj6E4X",
	"-round": "eKCkNcQ0N2h8zeth1lpA",
	"CameraFeedPlaceholder_icon": "f9dMY52B6cMEZ8nqGenJ"
};
export default ___CSS_LOADER_EXPORT___;
