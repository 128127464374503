import $ from "jquery";
import Sticky from "sticky-js";
import Masonry from "masonry-layout";
import "zenscroll";

import { isMobile } from "./helpers/isMobile";

// GENERAL
import "./helpers/siteNav";
import "./helpers/collapsible";
import "./helpers/injectComponent";

// Sticky item, such as sub menus
new Sticky("[sticky]");

if ($(".masonry").length) {
  new Masonry(".masonry", {
    itemSelector: ".masonry_item",
  });
}

// Good fallback on mobile where video does not play by default
if (isMobile()) {
  $("#video-intro").hide();
  $(".pageSection-home").removeClass("hidden");
}
