// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OQ6SMDS8Slg4vLUvDEEd{display:flex}.v2I8MPayoNGcutgCibUE{width:0;flex-grow:1;overflow:hidden;margin:0 4px}.QobdfNeFVEO92yyUxok_{width:5.5ex;flex-grow:0;flex-shrink:0}.n4N_RIkaybirxvWCbKDC{display:grid;grid-auto-flow:column;transition:transform .2s ease-in-out}.Lkhdj3GlVXWpC1fmTx3D{margin:0 4px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/appointment-bookings/AvailableSlots/components/PageableSlotList/styles.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CAEA,sBACE,OAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CAGF,sBACE,WAAA,CACA,WAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,oCAAA,CAGF,sBACE,YAAA","sourcesContent":["$theme: connectivf;\n@import \"../../../../../scss/variables.scss\";\n\n.SlotList {\n  display: flex;\n\n  &_porthole {\n    width: 0; // flex-grow will expand this\n    flex-grow: 1;\n    overflow: hidden;\n    margin: 0 $spacing-sm * 0.5;\n  }\n\n  &_arrowButton {\n    width: 5.5ex; // HACK until the Button does standard sizes\n    flex-grow: 0;\n    flex-shrink: 0;\n  }\n\n  &_allSlotButtons {\n    display: grid;\n    grid-auto-flow: column;\n    transition: transform 0.2s ease-in-out;\n  }\n\n  &_slotButtonContainer {\n    margin: 0 $spacing-sm * 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SlotList": "OQ6SMDS8Slg4vLUvDEEd",
	"SlotList_porthole": "v2I8MPayoNGcutgCibUE",
	"SlotList_arrowButton": "QobdfNeFVEO92yyUxok_",
	"SlotList_allSlotButtons": "n4N_RIkaybirxvWCbKDC",
	"SlotList_slotButtonContainer": "Lkhdj3GlVXWpC1fmTx3D"
};
export default ___CSS_LOADER_EXPORT___;
