// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VyjFPEQZNLZ3trA0q1wo{position:relative;overflow:hidden;height:100%}.VPbC2jqSFL780JnYSQTe{position:absolute;left:0;top:0;height:100%;overflow-y:auto;width:100%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/structure/ToggleViews/styles.scss"],"names":[],"mappings":"AAIA,sBACE,iBAAA,CACA,eAAA,CACA,WAAA,CAEA,sBACE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,WAAA,CACA,eAAA,CACA,UAAA","sourcesContent":["$theme: connectivf;\n@import \"../../../scss/variables.scss\";\n@import \"../../../scss/mixins/breakpoints\";\n\n.toggleViews {\n  position: relative;\n  overflow: hidden;\n  height: 100%;\n\n  &_tab {\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    overflow-y: auto;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleViews": "VyjFPEQZNLZ3trA0q1wo",
	"toggleViews_tab": "VPbC2jqSFL780JnYSQTe"
};
export default ___CSS_LOADER_EXPORT___;
