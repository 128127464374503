// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XI8nqpUPx69_gS6kMTRK{display:flex;flex-direction:column;height:100%}.XI8nqpUPx69_gS6kMTRK>*:first-child{height:100%;flex-grow:1}.XI8nqpUPx69_gS6kMTRK.PDVoMdshn7EbwUb20qj6>*:first-child{height:calc(100% - 55px)}.SYFb3aDriBJm7S4bsfA6{padding-top:4px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/atoms/Pager/styles.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CAEA,oCACE,WAAA,CACA,WAAA,CAGF,yDAGE,wBAAA,CAEF,sBACE,eAAA","sourcesContent":["$theme: connectivf;\n.PagerFrame {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n  > *:first-child {\n    height: 100%;\n    flex-grow: 1;\n  }\n\n  &.-hasFooter > *:first-child {\n    // HACK - 100% height will be the height of the outer component, so we manually\n    // add some space for the pager\n    height: calc(100% - 55px);\n  }\n  &_footer {\n    padding-top: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PagerFrame": "XI8nqpUPx69_gS6kMTRK",
	"-hasFooter": "PDVoMdshn7EbwUb20qj6",
	"PagerFrame_footer": "SYFb3aDriBJm7S4bsfA6"
};
export default ___CSS_LOADER_EXPORT___;
