// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fTYk3W0HcmkSXtzTwKX3{align-items:center;display:flex;flex-direction:column}.fYlA_nfDak1VnQSrhnbD{height:100%;width:100%;overflow:hidden;display:flex;justify-content:center}.ZrkvPZ6TkhJYAA6Zh6e2{width:100%;max-width:300px}.ZrkvPZ6TkhJYAA6Zh6e2>*{margin:5px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/Camera/styles.scss"],"names":[],"mappings":"AACA,sBAEE,kBAAA,CACA,YAAA,CACA,qBAAA,CAEA,sBACE,WAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,sBAAA,CAGF,sBACE,UAAA,CACA,eAAA,CAEA,wBACE,UAAA","sourcesContent":["$theme: connectivf;\n.Camera {\n  // display: inline-block; // we want the buttons to fit in the middle.\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n\n  &_wrapper {\n    height: 100%;\n    width: 100%;\n    overflow: hidden;\n    display: flex;\n    justify-content: center;\n  }\n\n  &_options {\n    width: 100%;\n    max-width: 300px;\n\n    > * {\n      margin: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Camera": "fTYk3W0HcmkSXtzTwKX3",
	"Camera_wrapper": "fYlA_nfDak1VnQSrhnbD",
	"Camera_options": "ZrkvPZ6TkhJYAA6Zh6e2"
};
export default ___CSS_LOADER_EXPORT___;
