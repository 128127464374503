// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._Dh3mum6mXaFg47e2pZv{display:block}._Dh3mum6mXaFg47e2pZv.ZFjvkTzQJ6eIB_MGAMTY{max-width:100%;max-height:100%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/branding/StaticLogo/styles.scss"],"names":[],"mappings":"AACA,sBAEE,aAAA,CAEA,2CACE,cAAA,CACA,eAAA","sourcesContent":["$theme: connectivf;\n.StaticLogo {\n  // margin: 0 auto;\n  display: block;\n\n  &.-responsive {\n    max-width: 100%;\n    max-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StaticLogo": "_Dh3mum6mXaFg47e2pZv",
	"-responsive": "ZFjvkTzQJ6eIB_MGAMTY"
};
export default ___CSS_LOADER_EXPORT___;
